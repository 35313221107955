import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { AuthService } from './auth.service';
import {Observable} from 'rxjs';
import {TransactionsService} from './transactions.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private authService: AuthService,
    private  _transactionService: TransactionsService
  ) { }

  // @ts-ignore
  async canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Promise<Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree> | boolean | UrlTree> {

    let result = await this._transactionService.getCompteeOperation__();

    console.log('result: ',result);

    if (this.auth.authenticated) {
      // return true;
      if(result.code != 403){
        return true;
      }else{
        this.authService.remove();
        this.router.navigateByUrl('/sessions/signin');
      }
    } else {
      this.router.navigateByUrl('/sessions/signin');
    }
  }

}
