import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import {ExamplePdfComponent} from './views/example-pdf/example-pdf.component';

const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    } ,
    {
      path: 'admin',
      loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule)
    },
  {
      path: 'institution',
      loadChildren: () => import('./views/institutions/institutions.module').then(m => m.InstitutionsModule)
    } ,{
      path: 'appro',
      loadChildren: () => import('./views/approvissionnement/approvissionnement.module').then(m => m.ApprovissionnementModule)
    },{
      path: 'point',
      loadChildren: () => import('./views/point-devente/point-devente.module').then(m => m.PointDeventeModule)
    },
  {
      path: 'paiemen_de_masse',
      loadChildren: () => import('./views/paiement-masse/paiement-masse.module').then(m => m.PaiementMasseModule)
    },
  {
    path: 'example',
    component: ExamplePdfComponent
  }
  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    canActivate: [AuthGaurd],
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
