import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()

export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if(Date.now() > parseInt(this.authService.getExpiredToken())){

    // add authorization header with jwt token if available
    let jwtToken = this.authService.getJwtToken();
    if (jwtToken) {
      if(request.method == 'POST' || request.method == 'PUT'){
        if( environment.apiAuthUrl + '/login' !== request.url ){
          request = request.clone({
            setHeaders: {
              'X-AUTH-TOKEN': `${jwtToken}`,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            }
          });
        }
      }else{
        request = request.clone({
          setHeaders: {
            'X-AUTH-TOKEN': `${jwtToken}`
          }
        });
      }

    }
    //else {
    //   if(![environment.apiAuthUrl + '/login',environment.apiAuthUrl + '/otp/verify',environment.apiAuthUrl + '/users/password/forget'].includes(request.url))
    //     this.authService.signout();
    // }

    return next.handle(request);

    // } else {
    //     this.authService.signout();
    // }
  }
}
