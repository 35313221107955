import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../../services/navigation.service';
import {SearchService} from '../../../../services/search.service';
import {AuthService} from '../../../../services/auth.service';
import {LocalStoreService} from '../../../../services/local-store.service';
import {TransactionsService} from '../../../../services/transactions.service';

@Component({
    selector: 'app-header-sidebar-large',
    templateUrl: './header-sidebar-large.component.html',
    styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {
    currentUser: any;
    notifications: any[];
    solde = 0;
    loading;

    constructor(
        private navService: NavigationService,
        public searchService: SearchService, private store: LocalStoreService,
        private auth: AuthService, private  _transactionService: TransactionsService) {
        this.currentUser = this.store.getItem('dashboard_current_user');
    }

    ngOnInit() {
        this.initSolde();
    }

    toggelSidebar() {
        const state = this.navService.sidebarState;
        if (state.childnavOpen && state.sidenavOpen) {
            return state.childnavOpen = false;
        }
        if (!state.childnavOpen && state.sidenavOpen) {
            return state.sidenavOpen = false;
        }
        if (!state.sidenavOpen && !state.childnavOpen) {
            state.sidenavOpen = true;
            setTimeout(() => {
                state.childnavOpen = true;
            }, 50);
        }
    }

    signout() {
        this.auth.signout();
    }

    initSolde() {
        this.loading = true;
        this._transactionService.getCompteOperation()
            .subscribe(res => {
                if (res.code === 200) {
                    console.log('Historique', res);
                    if (res.data.status) {
                        this.solde = res.data.solde;
                    }
                }
                this.loading = false;
            }, error => {
                console.log('Error fetching transactions', error);
            });
    }
}
