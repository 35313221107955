import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  constructor( private http: HttpClient) {
  }

  getTrsByStatus(pagination,status) {
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/transactions_detail/${status}?page=${pagination}`);
  }
  getHistoriqueTrs(pagination,query='') {
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/historiques_transactions?child=partenaire,pointDeVente,caisse&page=${pagination}${query}`);
  }

  getService(){
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/services`);
  }
  getServicesPartenaires(){
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/services_partenaires`);
  }

  getPartenaire(){
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/partenaires`);
  }
  getCompteOperation(){
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/comptes_operation`);
  }

  async getCompteeOperation__(){
    try {
      let res = await this.http.get<any>(`${environment.apiDashboard}/dashboard/comptes_operation`).toPromise();
      // console.log('res: ', res);
      if (res.data) {
        return res;
      }
    } catch (e) {
      return null;
    }
  }

  getTotalTrs(data={}){
    return this.http.post<any>(`${environment.apiDashboard}/dashboard/transaction_fonction_temps`,data);

  }
  public async getSelectList(url: string , text: string[] | string, logos: string = null,id:string="id"){
    if(url == null)
      url = `${environment.apiReseauUrl}/agences/all`;
    let res:any = await this.http.get<any>(url).toPromise();
    // let data = res.data;
    let data
    if(res['hydra:member']){
      data = res['hydra:member'];
    }
    else{
      data = res;
    }

    let options: { [x: string]: any; }[] = [];

    let data_ = data.map(function(item: { [x: string]: any; }, index: any) {
      let option = item;
      if(typeof text === typeof []){
        let txt = '';
        for (var i=0; i< text.length; i++){
          if(text[i].indexOf(".") != -1){
            let _=text[i].split('.');
            txt +=item[_[0]][_[1]];
          }else if(item[text[i]]){
            txt += item[text[i]];
          }else{
            txt += text[i];
          }
          if(i < text.length -1){
            if(logos != null){
              txt += " "+logos+" ";
            }else{
              txt += " ";
            }
          }
        }
        option.text = txt;
        option.id = item[id];
      }else{
        if(text.indexOf(".") != -1){
          if(typeof text === "string"){
            let _=text.split('.');
            let txt = item[_[0]][_[1]] + " ";
            option.text = txt;
            option.id = item[id];
          }
        }else{
          // @ts-ignore
          option.text = item[text];
          option.id = item[id];
        }
      }
      options.push(option);
    });
    return options;
  }
  getAllCaisses(codeAgence) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/caisses/all?pointDeVente.code=${codeAgence}`);
  }
  getCommission(page = '1,10',query = ''){
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/commission_bg`);
  }
  getListePartenaire(){
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/listePartenaire`);
  }

}
