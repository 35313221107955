import { Injectable } from '@angular/core';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //Only for demo purpose
  authenticated = true;

  constructor(private store: LocalStoreService, private router: Router,private http: HttpClient) {
    this.checkAuth();
  }

  checkAuth() {
     this.authenticated = this.store.getItem("dashboard_login_status");
  }

  getuser() {
    return of({});
  }

  signin(credentials) {
    return this.http.post<any>(`${environment.apiAuthUrl}/login`, credentials);
  }
  signout() {
    this.authenticated = false;
    this.store.setItem("dashboard_login_status", false);
    this.store.clear();
    this.router.navigateByUrl("/sessions/signin");
  }

    getJwtToken() {
      return this.store.getItem('dashboard_jwt_token');
    }

    setAuthenticated(value) {
    this.authenticated = value;
  }
  resetPassword(user) {
    return this.http.post<any>(`${environment.apiAuthUrl}/users/password/forget`, user);
  }

  public remove() {
    this.store.clear();

  }
}
